












import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TableHeader} from "@/interface/TableHeader";

@Component({})
export default class EntityPicker extends Mixins(ObjectInputMixin) {
    @Prop() label!: string;
    @Prop() items!: Array<any>;
    @Prop() headers!: Array<TableHeader>;

    get getHeaders(): Array<TableHeader> {
        return (this.headers ? this.headers : []).concat(
            <TableHeader>{
                value: 'picker',
                text:'Seç'
            }
        )
    }

    displayPicker: boolean = false;

    showPicker() {
        this.displayPicker = true;
    }

    hidePicker() {
        this.displayPicker = false;
    }

    onRowClick(val:any){
        this.hidePicker();
        this.$emit('input',val);
    }

}
