

















































































































































































































import {Component, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import islemGecmisi from "@/components/inputs/IslemGecmisi.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ItemArrayManagerTableHeader, TableHeader} from "@/interface/TableHeader";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import EntityTablePicker from "@/components/common-inputs/EntityTablePicker.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import AlacakKalemleriForm from "@/components/takip/form/AlacakKalemleriForm.vue";
import BankaBilgileriForm from "@/components/comps/forms/BankaBilgileriForm.vue";
import {BankaHesapListResponseProvider} from "@/services/BankaHesapService";
import {HesapNumarasiEntity} from "@/entity/HesapNumarasiEntity";

@Component({
    components: {
        BankaBilgileriForm,
        AlacakKalemleriForm,
        FormDialogButton,
        EntityTablePicker,
        Tutar,
        ParaBirimi,
        ItemArrayManager,
        IlcePicker,
        IlPicker,
        Dates,
        islemGecmisi,
    },
})
export default class Anasayfa extends Vue {
    labels = ["PZT", "SAL", "ÇAR", "PER", "CUM", "CMT", "PAZ"];
    time = 0;
    elapsedTime = "";
    paraBirimi = {};
    createdValue: any = null;
    valueToEdit = BankaHesapListResponseProvider().data[0];

    onCreateSuccess(val: any) {
        this.createdValue = val;
    }

    faizHesapHeaders: Array<ItemArrayManagerTableHeader> = [
        {value: "gecenGun", text: "Geçen Gün"},
        {value: "gunlukFaiz", text: "Günlük Faiz"},
        {value: "donemlikToplam", text: "Faiz Dönemlik Toplam"},
    ]
    hesaplar = BankaHesapListResponseProvider().data;

    hesapTableHeaders: Array<ItemArrayManagerTableHeader> = [
        {value: "hesapAdi", text: "Hesap Adı"},
        {value: "banka.isim", text: "Banka"},
        {value: "banka.hesapNo", renderer: (row: HesapNumarasiEntity) => this.$helper.hesapNoOrIbanStr(row)},
    ];

    hesapHeaders: Array<ItemArrayManagerTableHeader> = [
        {value: "hesapAdi", text: "Hesap Adı"},
        {value: "banka.hesapNo", renderer: (row: HesapNumarasiEntity) => this.$helper.hesapNoOrIbanStr(row)},
    ];
    alacak = {
        id: "1",
        tutar: "100",
        alacakTuru: "Tür",
        paraBirimi: ParaBirimiListResponseProvider().data[0],
    };
    alacaklar = [
        {
            id: "1",
            tutar: "100",
            alacakTuru: "Tür",
            paraBirimi: ParaBirimiListResponseProvider().data[0],
        },
        {
            id: "2",
            tutar: "101",
            alacakTuru: "Tür",
            paraBirimi: ParaBirimiListResponseProvider().data[0],
        },
        {
            id: "3",
            tutar: "102",
            alacakTuru: "Tür",
            paraBirimi: ParaBirimiListResponseProvider().data[0],
        },
        {
            id: "4",
            tutar: "20",
            alacakTuru: "Tür",
            paraBirimi: ParaBirimiListResponseProvider().data[1],
        },

    ];
    alacaklarHeaders: Array<TableHeader> = [
        {value: "tutar", text: "Tutar"},
        {value: "alacakTuru", text: "Alacak Türü"},
        {value: "paraBirimi.ad", text: "Para Birimi"},
    ];
    il = null;
    ilce = null;

    tarih = new Date("2020-04-04");//"2020-04-04";
    log() {
        console.log(this.tarih);
    }

    calculateTime() {
        this.elapsedTime = this.$helper.tarihGecenSure(this.tarih)
    }

    forecast = [
        {
            day: "Salı",
            icon: "mdi-white-balance-sunny",
            temp: "24\xB0/12\xB0"
        },
        {
            day: "Çarşamba",
            icon: "mdi-white-balance-sunny",
            temp: "22\xB0/12\xB0"
        },
        {day: "Perşembe", icon: "mdi-cloud", temp: "20\xB0/11\xB0"}
    ];
    value = [423, 446, 675, 510, 590, 610, 760];
};
